import React, { useEffect, useState } from 'react'
import stringLength from 'string-length'
import { Input, Radio } from '@nextui-org/react'
import Router from 'next/router'

import { MAX_FANME_ID_LENGTH, MAX_USER_NAME_LENGTH } from '@/constants'
import { Colors } from '@/constants/styles/color'
import { event } from '@/pages/api/gtag'
import FanmeLoading from '@/components/atoms/FanmeLoading'
import FullModal from '@/components/atoms/Dialog/FullModal'
import { properNouns } from '@/constants/proper_nouns'

import { useUser } from '@/libs/fanme_backend'
import { putForm } from '@/pages/api/base'
import { validAccountIdentityUsed } from '@/libs/utils'
import { useCurrentUser } from '@/contexts/AuthContext'
import { PURPOSES } from '@/constants/purpose'
import ModalHeaderWithSave from '@/components/molecules/Header/ModalHeaderWithSave'

import { useEditProfiletStore } from '@/utils/hooks/useProfileModal'
import ItemNotification from '../../Notification/ItemNotification'
import AvatarSelect from '../../AvatarSelect'

import {
  StyledContentContainer,
  StyledContentRow,
  StyledFormErrMsg,
  StyledFormLabel,
  StyledFormNote,
  StyledFormRequired,
  StyledFormTitle,
  StyledSpacer,
} from '../index.style'

interface Props {
  visible: boolean
  onClose: () => void
  toNext?: () => void
}

const _PREFIX_ = 'https://fanme.link/@'

const EditProfilePopup = ({ visible, onClose, toNext }: Props) => {
  const [avatarImage, setAvatarImage] = useState<any>(null)
  const [avatarCanvas, setAvatarCanvas] = useState<HTMLCanvasElement>()
  const [creatorName, setCreatorName] = useState('')
  const [accountIdentity, setAccountIdentity] = useState('')
  const [orinalAccountIdentity, setOrinalAccountIdentity] = useState('')
  const [fanmeIDAvailable, setFanmeIDAvailable] = useState(true)
  const [visibleToast, setVisibleToast] = useState(false)
  const [creatorNameErrMessage, setCreatorNameErrMessage] = useState('')
  const [fanmeIdErrMessage, setFanmeIdErrMessage] = useState('')
  const [loaded, setLoaded] = useState(false)
  const [purpose, setPurpose] = useState(0)
  const [isValidCreatorName, setIsValidCreatorName] = useState(true)
  const [isEdited, setIsEdited] = useState(false)

  const { refreshCurrentUser } = useCurrentUser()
  const { onClose: onCloseEditProfile } = useEditProfiletStore()
  const { mutate: mutateUserData } = useUser()

  const { data: profile } = useUser()

  useEffect(() => {
    if (profile) {
      setAvatarImage(profile.icon)
      setCreatorName(profile.name)
      setAccountIdentity(profile.account_identity)
      setOrinalAccountIdentity(profile.account_identity)
      setPurpose(profile.purpose)
    }
  }, [profile])

  const onChangeName = (e: any) => {
    const value = e.target.value.toString()
    setCreatorName(e.target.value)
    if (value.length === 0) {
      setCreatorNameErrMessage(`${properNouns.username}を入力してください`)
      setIsValidCreatorName(false)
      return
    }
    if (value.length > MAX_USER_NAME_LENGTH) {
      setCreatorNameErrMessage(`${MAX_USER_NAME_LENGTH}文字以内で入力してください`)
      setIsValidCreatorName(false)
      return
    }
    setCreatorNameErrMessage('')
    setIsValidCreatorName(true)
  }

  const goCreatorPage = (accountIdentity: string) => {
    const creatorPagePath = `/@${accountIdentity}`
    if (location.pathname !== creatorPagePath) {
      Router.push(creatorPagePath).then(() => Router.reload())
    } else {
      refreshCurrentUser()
      mutateUserData()
      onCloseEditProfile()
    }
  }

  const putCreatorData = async (formData: FormData) => {
    try {
      return await putForm('/creators/self', formData)
    } catch (e) {
      // TODO Error handling
    }
  }

  const updateProfile = async (formData: FormData) => {
    try {
      const creatorRes = await putCreatorData(formData)
      if (creatorRes && creatorRes.status === 200) {
        setLoaded(false)
        toNext ? toNext() : goCreatorPage(creatorRes.data.account_identity)
      }
    } catch (error) {
      console.error('Failed to update profile:', error)
    }
  }

  const updateProfileData = async () => {
    setLoaded(true)

    const formData = new FormData()
    formData.append('name', creatorName)
    formData.append('account_identity', accountIdentity)
    formData.append('purpose', purpose.toString())
    // 画像変更時にblobがないままデータ更新が行われていたため、条件分岐を追加
    if (avatarCanvas) {
      const canvas = avatarCanvas as HTMLCanvasElement
      canvas.toBlob(async blob => {
        if (!blob) return
        if (blob) formData.append('icon', blob)
        updateProfile(formData)
      })
    }
    updateProfile(formData)
  }

  const onUpdate = async () => {
    let idValidation = true
    if (isEdited) {
      idValidation = await checkFanmeIDAvailability(accountIdentity)
    }
    if (idValidation) updateProfileData()
    event({
      action: 'submit_form',
      category: 'information',
      value: 'update_profile',
    })
  }

  const onDiscard = () => {
    setAvatarImage(profile.icon)
    setCreatorName(profile.name)
    setAccountIdentity(profile.account_identity)
    setPurpose(profile.purpose)
    onClose()
  }

  const onChangeAccountId = (accountId: string): void => {
    if (accountId !== orinalAccountIdentity) setIsEdited(true)
    setAccountIdentity(accountId)
    setFanmeIdErrMessage('')
    checkAccountIdValid(accountId)
  }

  const checkAccountIdValid = (accountId: string) => {
    if (accountId == undefined || accountId.length == 0) {
      setFanmeIdErrMessage(`${properNouns.fanmeId}を入力してください`)
      setFanmeIDAvailable(false)
      return
    }

    if (!accountId.match(/^[a-zA-Z0-9._]*$/)) {
      setFanmeIdErrMessage('使用できない文字が含まれています')
      setFanmeIDAvailable(false)
      return
    }

    if (accountId.endsWith('.')) {
      setFanmeIdErrMessage('末尾に「.」は使用できません')
      setFanmeIDAvailable(false)
      return
    }

    if (accountId.length > MAX_FANME_ID_LENGTH) {
      setFanmeIdErrMessage(`${MAX_FANME_ID_LENGTH}文字以内で入力してください`)
      setFanmeIDAvailable(false)
      return
    }

    setFanmeIDAvailable(true)
    return
  }

  const handleFocusOnName = () => {
    if (creatorName === 'FANMEユーザー') {
      setCreatorName('')
    }
  }

  const checkFanmeIDAvailability = async (accountIdentity: string) => {
    if (!fanmeIDAvailable) return false

    try {
      const notInUseAccountIdentity = await validAccountIdentityUsed(accountIdentity)
      if (notInUseAccountIdentity) {
        setFanmeIDAvailable(true)
        setVisibleToast(true)
        setFanmeIdErrMessage('')
        setTimeout(() => setVisibleToast(false), 2000)
        return true
      } else {
        setFanmeIdErrMessage(`この${properNouns.fanmeId}は既に利用されています`)
        setFanmeIDAvailable(false)
        return false
      }
    } catch (e) {
      setFanmeIdErrMessage(`この${properNouns.fanmeId}は既に利用されています`)
      setFanmeIDAvailable(false)
      return false
    }
  }

  const renderHeader = () => (
    <ModalHeaderWithSave
      title="プロフィール編集"
      onAction={onUpdate}
      onCancel={onDiscard}
      disabled={!isValidCreatorName || !fanmeIDAvailable}
    />
  )

  const renderContent = () => (
    <StyledContentContainer>
      <StyledFormLabel>
        <StyledFormTitle>プロフィール画像</StyledFormTitle>
      </StyledFormLabel>
      <AvatarSelect
        avatarImage={avatarImage}
        creatorName={creatorName}
        accountIdentity={accountIdentity}
        onGetCanvas={setAvatarCanvas}
        slowUpdate
      />

      <StyledFormLabel>
        <StyledFormTitle>
          {properNouns.username}
          <StyledFormRequired />
        </StyledFormTitle>
        <span>
          {stringLength(creatorName)} / {MAX_USER_NAME_LENGTH}
        </span>
      </StyledFormLabel>
      <Input
        size="md"
        shadow={false}
        animated={false}
        autoCapitalize="off"
        aria-label={properNouns.username}
        placeholder={`${properNouns.username}を入力`}
        css={{
          h: '48px',
          width: '100%',
          label: {
            backgroundColor: Colors.ULTRA_LIGHT_GRAY,
            boxShadow: !isValidCreatorName ? `0 0 0 1px ${Colors.RED}` : 'none',
            borderRadius: '8px',
            h: '48px',
          },
          '::placeholder': {
            color: Colors.LIGHT_GRAY,
          },
          input: {
            backgroundColor: Colors.ULTRA_LIGHT_GRAY,
            m: '0 !important',
            p: '0 12px',
            color: Colors.PRIMARY_GRAY,
            br: '8px',
            fontSize: '13px',
            fontWeight: 500,
            lineHeight: '13px',
            h: '48px',
          },
        }}
        value={creatorName}
        onChange={onChangeName}
        onFocus={handleFocusOnName}
      />
      {!isValidCreatorName && <StyledFormErrMsg>{creatorNameErrMessage}</StyledFormErrMsg>}
      <StyledSpacer />

      <StyledFormLabel>
        <StyledFormTitle>
          {properNouns.fanmeId}
          <StyledFormRequired />
        </StyledFormTitle>
        <span>
          {stringLength(accountIdentity)} / {MAX_FANME_ID_LENGTH}
        </span>
      </StyledFormLabel>
      <StyledContentRow>
        {visibleToast && (
          <ItemNotification top={-40} text={`この${properNouns.fanmeId}は利用できます`} />
        )}
        <Input
          size="md"
          shadow={false}
          animated={false}
          autoCapitalize="off"
          placeholder="IDを入力"
          aria-label={properNouns.fanmeId}
          color={fanmeIDAvailable ? 'default' : 'error'}
          css={{
            h: '48px',
            width: '100%',
            '::placeholder': {
              color: Colors.LIGHT_GRAY,
            },
            label: {
              backgroundColor: Colors.ULTRA_LIGHT_GRAY,
              borderRadius: '8px',
              boxShadow: !fanmeIDAvailable ? `0 0 0 1px ${Colors.RED}` : 'none',
              h: '48px',
            },
            span: {
              backgroundColor: Colors.ULTRA_LIGHT_GRAY,
              borderTopLeftRadius: '8px',
              borderBottomLeftRadius: '8px',
              paddingRight: '4px',
              fontSize: '11px',
              fontWeight: 500,
              lineHeight: '11px',
              color: Colors.PRIMARY_GRAY,
            },
            input: {
              backgroundColor: Colors.ULTRA_LIGHT_GRAY,
              m: '0 !important',
              paddingRight: '12px',
              color: Colors.PRIMARY_GRAY,
              borderTopRightRadius: '8px',
              borderBottomRightRadius: '8px',
              fontSize: '14px',
              fontWeight: 600,
              h: '48px',
            },
          }}
          labelLeft={_PREFIX_}
          value={accountIdentity}
          onChange={e => onChangeAccountId(e.target.value)}
          onBlur={checkFanmeIDAvailability.bind(null, accountIdentity)}
        />
      </StyledContentRow>

      {!fanmeIDAvailable && <StyledFormErrMsg>{fanmeIdErrMessage}</StyledFormErrMsg>}
      <StyledFormNote>
        {`* 半角英数字と記号（"." "_"）のみ使用できます。末尾に「.」は使用できません`}
      </StyledFormNote>
      <StyledSpacer />

      {/*利用目的*/}
      <StyledFormLabel>
        <StyledFormTitle>利用目的</StyledFormTitle>
      </StyledFormLabel>
      <Radio.Group
        css={{
          w: '100%',
          m: '8px 0',
          gap: '24px',
          '.radioItem': {
            m: 0,
          },
          d: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
        }}
        value={purpose}
        onChange={e => setPurpose(e as number)}
      >
        {PURPOSES.map(purposeOption => {
          return (
            <Radio
              size="lg"
              key={purposeOption.type}
              value={purposeOption.type}
              className="radioItem"
              css={{
                '.nextui-radio-point': {
                  m: '0',
                  marginRight: '8px',
                  '&::after': {
                    bg: Colors.WHITE,
                    border: `6px solid ${Colors.VERY_LIGHT_GRAY}`,
                    position: 'relative',
                  },
                },
                '.nextui-radio-active': {
                  '&::after': {
                    bg: Colors.WHITE,
                    border: `6px solid ${Colors.FANME_YELLOW} !important`,
                    m: '0',
                  },
                },
                '.nextui-radio-name': {
                  fontSize: '12px',
                  wordBreak: 'keep-all',
                  color: Colors.PRIMARY_GRAY,
                },
              }}
            >
              {purposeOption.name}
            </Radio>
          )
        })}
      </Radio.Group>
    </StyledContentContainer>
  )

  if (!profile) return <FanmeLoading />
  return (
    <>
      {loaded && <FanmeLoading />}

      <FullModal
        header={renderHeader()}
        content={renderContent()}
        visible={visible}
        zIndex={10000}
        bodyPadding="16px"
      />
    </>
  )
}

export default EditProfilePopup
